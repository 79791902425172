@import "../../assets/utils/functions.scss";

.main {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 99vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: vw_d(38) vw_d(21);
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(255, 255, 255, 0)
  );
}
.socialContainer {
  display: flex;
  align-items: center;
}

.socialButtons {
  height: vw_d(39);
  margin: 0 vw_d(12);
  height: vw_d(40);
  cursor: pointer;
}

.logo {
  height: vh_d(42);
  margin: vw_d(17);
}

@media (max-width: 640px) {
  .main {
    padding: vw(31) vw(20);
  }
  .socialButtons {
    margin: 0 vw(12);
    height: vw(35);
  }
  .logo {
    height: vw(28);
    padding: 0 vw(12);
  }
  .logoContainer {
    padding-right: vw(12);
  }
}
