@import "../../assets/utils/functions.scss";
@font-face {
  font-family: almoniTzar;
  src: url(../../assets/fonts/almoni-tzar-aaa-regular.otf);
}
@font-face {
  font-family: openSansHebrewCondensed;
  src: url(../../assets/fonts/OpenSansHebrewCondensed-Regular.ttf);
}
@font-face {
  font-family: assistant;
  src: url(../../assets/fonts/assistant.ttf);
}
@font-face {
  font-family: alenbiSerif;
  src: url(../../assets/fonts/alenbiserifregular.otf);
}
html,
body {
  font-family: almoniTzar;
  overflow-x: hidden;
  position: relative;
  float: left;
}
.inline-image {
  height: vh_d(20);
}

@media (max-width: 640px) {
  .inline-image {
    height: vw(8);
  }
}
