@import "../../assets/utils/functions.scss";

.main {
  width: 100vw;
  background-position: top;
  background-repeat: repeat-y;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: vw_d(128);
  flex-direction: column;
  background-blend-mode: hard-light;
}

.container {
  width: vw_d(1024);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainSpeakerCardContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.speakerCardContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .container {
    width: 80%;
  }
  .main {
    background-size: contain;
  }
}
