@import "../../assets/utils/functions.scss";

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w100 {
  width: 100%;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.titleBox {
  padding: vw_d(4) vw_d(54);
  background-color: #ffb733;
  margin: 0 vw_d(24);
}

.headerLine {
  border-color: white;
  width: 100%;
}

.headerTitle {
  color: white;
  font-size: vw_d(40);
  font-weight: 400;
  margin: 0;
}

.description,
.description:visited {
  text-decoration: none;
  color: white;
  font-size: vw_d(33);
  font-weight: 400;
  margin: 0;
  margin-top: vw_d(8);
  margin-bottom: vw_d(78);
}
.desc_line2 {
  font-size: vw_d(24);
  margin-left: vw_d(8);
}
@media (max-width: 640px) {
  .listHeader {
    margin-top: vw(44);
  }
  .titleBox {
    padding: vw(6) vw(37);
    margin: 0 vw(12);
  }
  .headerTitle {
    font-size: vw(22);
  }
  .description {
    font-size: vw(17);
    margin-bottom: vw(50);
  }
  .desc_line2 {
    font-size: vw(12);
  }
}
